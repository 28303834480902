import useApi from '~/composables/useApi';
import mutationString from './mutationString.gql';
import { useCartStore } from '~/modules/checkout/stores/cart';

const useCustomRemoveFromCart = () => {
  const { mutate } = useApi();
  const cartStore = useCartStore();
  const removeItem = async (inputData) => {
    return mutate(mutationString, {input: inputData})
      .then((data : any) => {
        const updatedCart = data.data.removeItemFromCart.cart
        cartStore.cart = updatedCart
        return data;
      })
      .catch((e) => {
        return e
      });
  }
  return {
    removeItem
  }
}

export default useCustomRemoveFromCart